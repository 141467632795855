/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { PrevPage, NextPage } from "../../components/PrevNextPage";
import { formatLocalDate } from "../../utils/dateTime";
import { stripPrefix } from "../../utils/stringUtils";
import Breadcrumbs from "../../components/Breadcrumbs";
import Seo from "../../components/Seo";
import { BaseStyles, Container } from "theme-ui";
import Event from "./Event";

const Template = ({ data, location }) => {
  const { event, prevEvent: prev, nextEvent: next } = data.calendar;
  const { summary } = event;
  const strippedSummary = stripPrefix(summary, " - ");

  let numberOfRefs = [prev, next].filter((x) => x != null).length;
  const prevStartDay = prev?.start?.date
    ? new Date(prev?.start?.date)
    : new Date(prev?.start?.dateTime);
  const nextStartDay = next?.start?.date
    ? new Date(next?.start?.date)
    : new Date(next?.start?.dateTime);

  return (
    <>
      <Seo
        title={`${strippedSummary} | Termine`}
        pathname={location.pathname}
        description={`${strippedSummary}`}
      />

      <Layout>
        <BaseStyles>
          <Breadcrumbs
            names={["Termine", strippedSummary]}
            location={location}
          />
          <div
            sx={{ variant: "layout.grid.prevNext", "--numRows": numberOfRefs }}
          >
            {prev && (
              <div sx={{ variant: "layout.grid.prev" }}>
                <PrevPage link={prev.slug}>
                  <span sx={{ variant: "prevNext.meta" }}>
                    vorheriger Termin:
                  </span>
                  <div sx={{ variant: "prevNext.text" }}>
                    {prev.summary}, ab {formatLocalDate(prevStartDay)}
                  </div>
                </PrevPage>
              </div>
            )}
            <div sx={{ variant: "layout.grid.main" }}>
              <Container>
                <Event {...event} summary={strippedSummary} noHover />
              </Container>
            </div>
            {next && (
              <div sx={{ variant: "layout.grid.next" }}>
                <NextPage link={next.slug}>
                  <span sx={{ variant: "prevNext.meta" }}>
                    nächster Termin:
                  </span>
                  <div sx={{ variant: "prevNext.text" }}>
                    {next.summary}, ab {formatLocalDate(nextStartDay)}
                  </div>
                </NextPage>
              </div>
            )}
          </div>
        </BaseStyles>
      </Layout>
    </>
  );
};

export default Template;

export const eventsPageQuery = graphql`
  query EventsPage($id: String!, $prevId: String!, $nextId: String!) {
    calendar {
      event: getEvent(input: { event_key: $id }) {
        summary
        start {
          ... on Calendar_DateObject {
            date
          }
          ... on Calendar_DateTimeObject {
            dateTime
          }
        }
        end {
          ... on Calendar_DateObject {
            date
          }
          ... on Calendar_DateTimeObject {
            dateTime
          }
        }
        event_key
        calendars {
          color
          event_label
          priority
        }
      }
      nextEvent: getEvent(input: { event_key: $nextId }) {
        summary
        slug
        start {
          ... on Calendar_DateObject {
            date
          }
          ... on Calendar_DateTimeObject {
            dateTime
          }
        }
        event_key
      }
      prevEvent: getEvent(input: { event_key: $prevId }) {
        summary
        slug
        start {
          ... on Calendar_DateObject {
            date
          }
          ... on Calendar_DateTimeObject {
            dateTime
          }
        }
        event_key
      }
    }
  }
`;
