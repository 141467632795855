/** @jsxImportSource theme-ui */
import React from "react";
import { formatLocalTime } from "../../utils/dateTime";
import { Card } from "theme-ui";
import { Themed } from "@theme-ui/mdx";

const getFormattedDate = (
  date,
  { day = true, month = true, year = true } = {}
) => {
  const str = date.toLocaleDateString("de-DE", {
    day: day ? "numeric" : undefined,
    month: month ? "short" : undefined,
    year: year ? "numeric" : undefined,
    weekday: "short",
  });
  return month || year ? str : `${str}.`; // make sure dot is always added after day
};

const EventDate = ({ date }) => (
  <time
    dateTime={date.toISOString()}
    sx={{ variant: "cards.events.dateContainer" }}
  >
    {getFormattedDate(date)
      .split(" ")
      .map((x, idx) => (
        <span sx={{ variant: "cards.events.date" }} key={`eventDate-${idx}`}>
          {x} <br />
        </span>
      ))}
  </time>
);

const DateDivider = ({ ...props }) => (
  <span sx={{ variant: "cards.events.divider" }} {...props}>
    {" "}
    bis{" "}
  </span>
);

const Event = ({
  event_key,
  slug,
  start,
  end,
  summary,
  calendars,
  noHover,
  ...props
}) => {
  const startDate = start.date
    ? new Date(start.date)
    : new Date(start.dateTime);
  let endDate = end.date ? new Date(end.date) : new Date(end.dateTime);

  // If date property on end date exists, it is a full day event. So, subtract one day
  // from it because the Calendar API treats dates like 25/02 - 26/02 as 25/02T00:00 - 27/02T00:00.
  if (end.date) {
    const tmpEndDate = new Date(endDate);
    endDate = new Date(tmpEndDate.setDate(tmpEndDate.getDate() - 1));
  }
  const isOneDay = startDate.toDateString() === endDate.toDateString();

  return (
    <Card variant="sideLabelled" {...props}>
      <header sx={{ variant: "cards.events.header" }} className="hoverStyles">
        <div sx={{ variant: "cards.events.datesContainer" }}>
          <EventDate date={startDate} />
          {!isOneDay && (
            <React.Fragment>
              <DateDivider />
              <EventDate date={endDate} />
            </React.Fragment>
          )}
        </div>
        <div sx={{ variant: "cards.events.time" }}>
          {start.date
            ? `ganztägig`
            : start.dateTime &&
              end.dateTime &&
              `${formatLocalTime(start.dateTime)} - ${formatLocalTime(
                end.dateTime
              )} Uhr`}
        </div>
      </header>
      <main sx={{ variant: "cards.events.container" }}>
        <Themed.h3>{summary}</Themed.h3>
        <ul sx={{ variant: "list.badges" }}>
          {calendars.map(({ event_label }) => (
            <li key={`${event_key}-${event_label}`}>{event_label}</li>
          ))}
        </ul>
      </main>
    </Card>
  );
};

export default Event;
